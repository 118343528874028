<template>
  <component
    :is="generic ? `div` : `h${level}`"
    ref="headingRef"
    class="transform-gpu"
    :class="[
      {
        'font-serif text-5xl lg:text-8xl text-neutral-900': level === 1,
        'font-serif text-3xl lg:text-6xl hd:text-7xl': level === 2
      },
      isMounted ? 'opacity-100' : 'opacity-0'
    ]"
  >
    <span ref="textRef" v-html="props.content"></span>
  </component>
</template>

<script setup lang="ts">
import { SplitText } from 'gsap/SplitText'
import { gsap } from 'gsap'

interface Props {
  generic?: boolean
  level?: 1 | 2 | 3 | 4 | 5 | 6
  content: string
  isAnimated?: boolean
  delay?: number
  animate?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  generic: true,
  level: 2,
  isAnimated: true,
  delay: 0,
  animate: true
})

const headingRef = ref<HTMLElement | null>(null)
const textRef = ref<HTMLElement | null>(null)
const split = ref<null | any>(null)
const tween = ref<null | any>(null)
const isMarkerVisible = ref(false)
const isMounted = ref(false)

const { stop } = useIntersectionObserver(
  headingRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting && props.animate) {
      tween.value?.play()
    }
  },
  {
    threshold: 0.1
  }
)

watch(
  () => props.animate,
  (_, oldValue) => {
    if (!oldValue) {
      tween.value?.play()
    }
  }
)

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{ (event: 'completed'): void }>()

onMounted(() => {
  gsap.registerPlugin(SplitText)
  isMounted.value = true

  if (!props.isAnimated) {
    isMarkerVisible.value = true
  }

  if (textRef.value && props.isAnimated) {
    split.value = new SplitText(textRef.value, {
      type: 'lines, words',
      linesClass: 'split-child'
    })

    gsap.set(split.value.words, { perspective: 900 })

    const childSplit = new SplitText(textRef.value, {
      type: 'lines ,words',
      linesClass: 'split-child'
    })

    gsap.set(childSplit.words, { perspective: 900 })

    tween.value = gsap.from(childSplit.words, {
      delay: props.delay,
      yPercent: 350,
      duration: 1.2,
      ease: 'power4.out',
      stagger: {
        each: 0.08
      },
      paused: true,
      onStart: () => {
        isMarkerVisible.value = true
      },
      onComplete: () => {
        emit('completed')
      }
    })
  }
})

onUnmounted(() => {
  tween?.value?.kill()
  stop()
})
</script>
